const DayViewMobile = () => import("./DayViewMobile");
const DayViewDesktop = () => import("./DayViewDesktop");

/**
 * Daybook!
 *
 * Desktop:
 *    <v-app-bar>Date</v-app-bar>
 *    <v-container>
 *      <v-navigation-drawer right>Shifts</v-navigation-drawer>
 *      <v-row>
 *        <v-col>Tasks</v-col>
 *        <v-col>Notes</v-col>
 *      </v-row>
 *      <v-speed-dial />
 *      <v-bottom-sheet>KPIs</v-bottom-sheet>
 *    </v-container>
 *
 * Mobile:
 *    <v-app-bar>
 *       Date
 *       <v-tabs>
 *    </v-app-bar>
 *    <v-container>
 *      <keep-alive :key="day">
 *      <component :is="tab" />
 *      <component :is="tab-fab-btn" />
 *      </keep-alive>
 *    </v-container>
 */
export default {
  functional: true,
  inheritAttrs: false,
  render: (h, context) => {
    const responsiveComponent = context.parent.$vuetify.breakpoint.smAndDown
      ? DayViewMobile
      : DayViewDesktop;

    return h(
      responsiveComponent,
      {
        props: context.props,
        ...context.data,
        scopedSlots: context.scopedSlots,
      },
      context.$slots.default
    );
  },
};
